import colors from '@pickles/shared/utils/colors';
import React, { FC } from 'react';
import { IconInterface } from '.';

export const ButtonArrow: FC<IconInterface> = ({
  width = 19,
  height = 12,
  strokeWidth = 1.8,
  strokeColor = colors.san_juan_grey,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.09082 6H17.4545"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0908 1.63647L17.4545 6.00011L13.0908 10.3637"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
