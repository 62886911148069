import colors from '@pickles/shared/utils/colors';
import React, { FC } from 'react';
import { IconInterface } from '.';

export const PrebidsCardIcon: FC<IconInterface> = ({
  width = 18,
  height = 18,
  strokeColor = colors.san_juan_grey,
  strokeWidth = 1.5,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.2851 5.27344L7.77734 9.78125L6 7.99609"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.84375 17.0312H16.2188"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4062 13.4737H2.65625C2.06975 13.4737 1.59375 13.0936 1.59375 12.6252V2.44232C1.59375 1.97391 2.06975 1.59375 2.65625 1.59375H15.4062C15.9927 1.59375 16.4688 1.97391 16.4688 2.44232V12.6252C16.4688 13.0936 15.9927 13.4737 15.4062 13.4737Z"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
