const sizes = {
  XS: '480px',
  S: '576px',
  M: '768px',
  L: '992px',
  XL: '1170px',
  XXL: '1340px',
  XXXL: '1600px',
};

export default sizes;
