import colors from '@pickles/shared/utils/colors';
import React, { FC } from 'react';
import { IconInterface } from '.';

export const ItemDetailsIcon: FC<IconInterface> = ({
  width = 18,
  height = 18,
  strokeColor = colors.blumine_main_blue,
  strokeWidth = 1.5,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <clipPath id="clip0_1536_28995">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
      <g clipPath="url(#clip0_1536_28995)">
        <path
          d="M10.6875 8.4375H14.0625"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.6875 11.8125H14.0625"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.3125 2.8125H14.0625V3.375C14.0625 3.82255 13.8847 4.25178 13.5682 4.56824C13.2518 4.88471 12.8226 5.0625 12.375 5.0625C11.9274 5.0625 11.4982 4.88471 11.1818 4.56824C10.8653 4.25178 10.6875 3.82255 10.6875 3.375V2.8125H7.3125V3.375C7.3125 3.82255 7.13471 4.25178 6.81824 4.56824C6.50178 4.88471 6.07255 5.0625 5.625 5.0625C5.17745 5.0625 4.74822 4.88471 4.43176 4.56824C4.11529 4.25178 3.9375 3.82255 3.9375 3.375V2.8125H1.6875C1.38913 2.8125 1.10298 2.93103 0.892005 3.142C0.681026 3.35298 0.5625 3.63913 0.5625 3.9375V14.0625C0.5625 14.3609 0.681026 14.647 0.892005 14.858C1.10298 15.069 1.38913 15.1875 1.6875 15.1875H16.3125C16.6109 15.1875 16.897 15.069 17.108 14.858C17.319 14.647 17.4375 14.3609 17.4375 14.0625V3.9375C17.4375 3.63913 17.319 3.35298 17.108 3.142C16.897 2.93103 16.6109 2.8125 16.3125 2.8125V2.8125Z"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.3125 8.4375H3.9375V11.8125H7.3125V8.4375Z"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
