import colors from '@pickles/shared/utils/colors';
import React, { FC } from 'react';
import { IconInterface } from '.';

export const OutbidIcon: FC<IconInterface> = ({
  width = 17,
  height = 16,
  strokeColor = colors.san_juan_grey,
  strokeWidth = 1.2,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.89844 12.407C8.89844 12.6831 8.67458 12.907 8.39844 12.907C8.1223 12.907 7.89844 12.6831 7.89844 12.407C7.89844 12.1308 8.1223 11.907 8.39844 11.907C8.67458 11.907 8.89844 12.1308 8.89844 12.407Z"
        fill="white"
        stroke={strokeColor}
      />
      <path
        d="M8.39844 4.90698V9.90698"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.13947 13.183L7.36847 1.61502C7.46866 1.42909 7.61731 1.27374 7.79865 1.16545C7.97999 1.05717 8.18726 1 8.39847 1C8.60968 1 8.81696 1.05717 8.9983 1.16545C9.17964 1.27374 9.32829 1.42909 9.42847 1.61502L15.6575 13.183C15.7533 13.3612 15.8012 13.5612 15.7966 13.7635C15.7921 13.9658 15.7351 14.1634 15.6313 14.3371C15.5276 14.5108 15.3805 14.6546 15.2046 14.7545C15.0286 14.8544 14.8298 14.9069 14.6275 14.907H2.16947C1.96715 14.9069 1.7683 14.8544 1.59236 14.7545C1.41641 14.6546 1.26937 14.5108 1.1656 14.3371C1.06183 14.1634 1.00488 13.9658 1.0003 13.7635C0.99572 13.5612 1.04367 13.3612 1.13947 13.183V13.183Z"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
