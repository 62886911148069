import colors from '@pickles/shared/utils/colors';
import React, { FC } from 'react';
import { IconInterface } from '.';

export const WinningIcon: FC<IconInterface> = ({
  width = 17,
  height = 17,
  strokeColor = colors.san_juan_grey,
  strokeWidth = 1.4,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 1C10.4891 1 12.3968 1.79018 13.8033 3.1967C15.2098 4.60322 16 6.51088 16 8.5C16 10.4891 15.2098 12.3968 13.8033 13.8033C12.3968 15.2098 10.4891 16 8.5 16"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.031 9.12193C1.014 8.91693 1 8.70993 1 8.49993C1 8.28993 1.014 8.08293 1.031 7.87793"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.57422 2.118C4.92736 1.89858 5.29834 1.70924 5.68322 1.552"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.81055 5.12101C1.9981 4.75031 2.21654 4.39606 2.46355 4.06201"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.4498 12.92C2.20408 12.5839 1.98667 12.228 1.7998 11.856"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.031 7.87793C1.014 8.08293 1 8.28993 1 8.49993C1 8.70993 1.014 8.91693 1.031 9.12193"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.68322 1.552C5.29834 1.70924 4.92736 1.89858 4.57422 2.118"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.46355 4.06201C2.21654 4.39606 1.9981 4.75031 1.81055 5.12101"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.56055 14.874C4.91464 15.0948 5.28661 15.2854 5.67255 15.444"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.7998 11.856C1.98667 12.228 2.20408 12.5839 2.4498 12.92"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 9L7 11L12 6"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
