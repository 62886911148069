import colors from '@pickles/shared/utils/colors';
import React, { FC } from 'react';
import { IconInterface } from '.';

export const SoundOffIcon: FC<IconInterface> = ({
  width = 20,
  height = 20,
  strokeColor = colors.san_juan_grey,
  strokeWidth = 2,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4030_42267)">
        <path
          d="M15.0015 9.1665V18.3332L9.8457 14.4665"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.66797 13.3332H1.66797V6.6665H8.33464L15.0013 1.6665V4.99984"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.1673 0.833252L0.833984 19.1666"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4030_42267">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
