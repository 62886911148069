import colors from '@pickles/shared/utils/colors';
import React, { FC } from 'react';
import { IconInterface } from '.';

export const WinningFilledIcon: FC<IconInterface> = ({
  width = 18,
  height = 18,
  fillColor = colors.rhino_black,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="9.00036" cy="9.00011" rx="7.36364" ry="7.36364" fill="white" />
      <path
        d="M9 0C4.05 0 0 4.05 0 9C0 13.95 4.05 18 9 18C13.95 18 18 13.95 18 9C18 4.05 13.95 0 9 0ZM7.875 12.825L4.05 9L5.625 7.425L7.875 9.675L12.375 5.175L13.95 6.75L7.875 12.825Z"
        fill={fillColor}
      />
    </svg>
  );
};
