import { useRouter } from "next/router";

const useGoBack = () => {
  const router = useRouter();

  const goBack = () => {
    const historyLength = window.history.length;

    if (historyLength > 2) {
      router.back();
    } else {
      router.push('/');
    }
  };

  return goBack;
};

export default useGoBack;