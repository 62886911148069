import colors from '@pickles/shared/utils/colors';
import React, { FC } from 'react';
import { IconInterface } from '.';

export const PrebidsIcon: FC<IconInterface> = ({
  width = 24,
  height = 24,
  strokeColor = colors.blumine_main_blue,
  fillColor = colors.blumine_main_blue,
  strokeWidth = 1,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.1"
        d="M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z"
        fill={fillColor}
      />
      <defs>
        <clipPath id="clip0_1536_28659">
          <rect width="13.7143" height="13.7143" fill="white" transform="translate(5 5)" />
        </clipPath>
      </defs>
      <g clipPath="url(#clip0_1536_28659)">
        <path
          d="M14.36 9.01784L10.9255 12.4524L9.57134 11.0922"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.40479 17.9763H17.3572"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.7382 15.2658H7.02388C6.57702 15.2658 6.21436 14.9761 6.21436 14.6193V6.86089C6.21436 6.504 6.57702 6.21436 7.02388 6.21436H16.7382C17.185 6.21436 17.5477 6.504 17.5477 6.86089V14.6193C17.5477 14.9761 17.185 15.2658 16.7382 15.2658Z"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
