import colors from '@pickles/shared/utils/colors';
import React, { FC } from 'react';
import { IconInterface } from '.';

export const InspectionRemarksIcon: FC<IconInterface> = ({
  width = 17,
  height = 17,
  strokeColor = colors.blumine_main_blue,
  strokeWidth = 1.2,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0001 16.0001L12 12"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeMiterlimit="10"
      />
      <path
        d="M7.2069 13.4138C10.6349 13.4138 13.4138 10.6349 13.4138 7.2069C13.4138 3.77892 10.6349 1 7.2069 1C3.77892 1 1 3.77892 1 7.2069C1 10.6349 3.77892 13.4138 7.2069 13.4138Z"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M3.7998 6.77419C4.18774 5.32591 5.32567 4.18798 6.77394 3.80005"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
};
