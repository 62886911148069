import colors from '@pickles/shared/utils/colors';
import React, { FC } from 'react';
import { IconInterface } from '.';

export const IntakeIcon: FC<IconInterface> = ({
  width = 16,
  height = 16,
  strokeColor = colors.blumine_main_blue,
  strokeWidth = 1.5,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <clipPath id="clip0_1536_28994">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
      <g clipPath="url(#clip0_1536_28994)">
        <path
          d="M3.5 3.5H1.5C0.948 3.5 0.5 3.948 0.5 4.5V14.5C0.5 15.052 0.948 15.5 1.5 15.5H12.5C13.052 15.5 13.5 15.052 13.5 14.5V11.5"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.5 0.5L15.5 4.5L11.5 8.5"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.5 4.5H10.5C8.291 4.5 6.5 6.291 6.5 8.5V10.5"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
