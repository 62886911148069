import colors from '@pickles/shared/utils/colors';
import React, { FC } from 'react';
import { IconInterface } from '.';

export const HeartIcon: FC<IconInterface> = ({
  width = 20,
  height = 18,
  fillColor = 'none',
  strokeColor = colors.san_juan_grey,
  strokeWidth = 1.5,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 18"
      fill={fillColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.1715 2.85234C15.4013 1.05011 12.5307 1.05011 10.7606 2.85234C10.4433 3.17541 10.2041 3.54232 10.0001 3.92423C9.79615 3.54232 9.55703 3.17425 9.23858 2.85119C7.4684 1.04895 4.59781 1.04895 2.82763 2.85119C1.05746 4.65343 1.05746 7.57601 2.82763 9.37825L10.0001 16.4995L17.1715 9.37825C18.9428 7.57601 18.9428 4.65458 17.1715 2.85234Z"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
