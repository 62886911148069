import styled from 'styled-components';
import COLORS from '@pickles/shared/utils/colors';
import sizes from '../../../util/sizes';

interface IFieldWrapper {
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  width?: string;
  height?: string;
}

interface IInputField {
  isSearch?: boolean;
  borderRadius?: number;
  color?: string;
  height?: number;
  placeholderOnCenter?: boolean;
  paddingLeft?: number;
  paddingTop?: number;
  error?: boolean;
}

interface IIconImg {
  hasHeadingTitle?: boolean;
}

export const FieldWrapper = styled.div<IFieldWrapper>`
  margin-bottom: ${({ marginBottom = 30 }) => marginBottom}px;
  margin-top: ${({ marginTop = 0 }) => marginTop}px;
  margin-left: ${({ marginLeft = 0 }) => marginLeft}px;
  margin-right: ${({ marginRight = 0 }) => marginRight}px;
  width: ${({ width = '100%' }) => `calc(${width})`};
  height: ${({ height }) => height};
  position: relative;
  display: flex;
  flex-direction: column;
  @media (max-width: ${sizes.S}) {
    width: 370px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  @media (max-width: ${sizes.XS}) {
    width: 100%;
  }
`;

export const InputField = styled.input<IInputField>`
  width: 100%;
  border: 1px solid ${({ error }) => (error ? COLORS.cerise_red : COLORS.mischka)};
  border-radius: ${({ borderRadius = 100 }) => borderRadius}px;
  padding-left: ${({ paddingLeft = 42 }) => paddingLeft}px;
  padding-top: ${({ paddingTop = 0 }) => paddingTop}px;
  height: ${({ height = 44 }) => height}px;
  outline: none;
  color: ${COLORS.san_juan_grey};
  font-family: BasierCircle-Medium;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    background: transparent;
    transition: background-color 5000s ease-in-out 0s;
  }

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: #8594a9;
    font-size: 15px;
    font-family: BasierCircle;
  }
`;

export const FieldHeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const ForgotPasswordText = styled.div`
  cursor: pointer;
  font-family: BasierCircle-Medium;
  color: #1c4d8e;
  font-weight: 400;
  font-size: 15px;
`;

export const FieldHeading = styled.div`
  font-family: BasierCircle-Medium;
  font-weight: 400;
  font-size: 15px;
  color: #263a54;
`;

export const FieldError = styled.div`
  font-family: BasierCircle;
  font-weight: 400;
  font-size: 13px;
  color: ${COLORS.cerise_red};
  margin-top: 6px;
  text-align: left;
`;

export const IconImg = styled.img<IIconImg>`
  position: absolute;
  left: 20px;
  top: ${({ hasHeadingTitle }) => (hasHeadingTitle ? '40px' : '20px')};
`;
export const IconImgWrapper = styled.div`
  position: absolute;
  left: 17.5px;
  top: 13px;
`;

export const ShowPassImg = styled.img<IIconImg>`
  position: absolute;
  top: ${({ hasHeadingTitle }) => (hasHeadingTitle ? '42px' : '22px')};
  width: 15px;
  right: 18px;
  cursor: pointer;
`;
export const ShowPassImgWrapper = styled.div<IIconImg>`
  position: absolute;
  top: 15px;
  width: 15px;
  right: 18px;
  cursor: pointer;
`;

export const TextareaField = styled.textarea<IInputField>`
  width: 100%;
  border: 1px solid ${({ error }) => (error ? COLORS.cerise_red : COLORS.mischka)};
  border-radius: ${({ borderRadius = 100 }) => borderRadius}px;
  padding-left: ${({ paddingLeft = 42 }) => paddingLeft}px;
  padding-top: ${({ paddingTop = 0 }) => paddingTop}px;
  height: ${({ height = 140 }) => height}px;
  color: ${COLORS.san_juan_grey};
  font-family: BasierCircle-Medium;
  outline: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  resize: none;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    background: transparent;
    transition: background-color 5000s ease-in-out 0s;
  }

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${COLORS.bali_hai};
    font-size: 15px;
    font-family: BasierCircle;
  }
`;
