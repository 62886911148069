import colors from '@pickles/shared/utils/colors';
import React, { FC } from 'react';
import { IconInterface } from '.';

export const OutbidFilledIcon: FC<IconInterface> = ({
  width = 18,
  height = 18,
  fillColor = colors.rhino_black,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <clipPath id="clip0_1536_29082">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
      <g clipPath="url(#clip0_1536_29082)">
        <ellipse cx="9" cy="10.125" rx="4.5" ry="5.625" fill="white" />
        <path
          d="M17.775 14.0918L10.6683 0.9901C10.4931 0.665048 10.2258 0.398937 9.89996 0.2251C9.4573 -0.0117898 8.939 -0.0643169 8.45782 0.0789442C7.97663 0.222205 7.57146 0.549666 7.33046 0.9901L0.224957 14.0918C0.0695866 14.3802 -0.00809824 14.704 -0.000463484 15.0314C0.00717128 15.3588 0.0998629 15.6786 0.268503 15.9594C0.437144 16.2401 0.67593 16.4722 0.961398 16.6328C1.24687 16.7933 1.56919 16.8768 1.89671 16.8751H16.1032C16.4171 16.8751 16.7262 16.7978 17.0032 16.6501C17.2227 16.5336 17.4169 16.3748 17.5746 16.1829C17.7324 15.991 17.8507 15.7698 17.9225 15.532C17.9944 15.2941 18.0184 15.0444 17.9933 14.7973C17.9681 14.5501 17.8943 14.3103 17.7761 14.0918H17.775ZM8.99996 14.6251C8.77745 14.6251 8.55995 14.5591 8.37494 14.4355C8.18994 14.3119 8.04574 14.1362 7.96059 13.9306C7.87544 13.7251 7.85317 13.4989 7.89657 13.2806C7.93998 13.0624 8.04713 12.8619 8.20446 12.7046C8.3618 12.5473 8.56225 12.4401 8.78048 12.3967C8.99871 12.3533 9.22491 12.3756 9.43048 12.4607C9.63604 12.5459 9.81174 12.6901 9.93536 12.8751C10.059 13.0601 10.125 13.2776 10.125 13.5001C10.125 13.7985 10.0064 14.0846 9.79545 14.2956C9.58447 14.5066 9.29833 14.6251 8.99996 14.6251ZM10.125 10.6876C10.125 10.8368 10.0657 10.9799 9.96021 11.0853C9.85472 11.1908 9.71164 11.2501 9.56246 11.2501H8.43746C8.28827 11.2501 8.1452 11.1908 8.03971 11.0853C7.93422 10.9799 7.87496 10.8368 7.87496 10.6876V6.1876C7.87496 6.03842 7.93422 5.89534 8.03971 5.78985C8.1452 5.68436 8.28827 5.6251 8.43746 5.6251H9.56246C9.71164 5.6251 9.85472 5.68436 9.96021 5.78985C10.0657 5.89534 10.125 6.03842 10.125 6.1876V10.6876Z"
          fill={fillColor}
        />
      </g>
    </svg>
  );
};
