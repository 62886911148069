import colors from '@pickles/shared/utils/colors';
import React, { FC } from 'react';
import { IconInterface } from '.';

export const AuctionsRemarkIcon: FC<IconInterface> = ({
  width = 17,
  height = 17,
  strokeColor = colors.blumine_main_blue,
  strokeWidth = 1.2,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 6H12"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 9H12"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 7.5C16 3.91 12.642 1 8.5 1C4.358 1 1 3.91 1 7.5C1 11.09 4.358 14 8.5 14C9.025 14 9.537 13.952 10.032 13.863L14 16V11.909C15.238 10.75 16 9.204 16 7.5Z"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
