import colors from '@pickles/shared/utils/colors';
import React, { FC } from 'react';
import { IconInterface } from '.';

export const SpecificationsIcon: FC<IconInterface> = ({
  width = 18,
  height = 18,
  strokeColor = colors.blumine_main_blue,
  strokeWidth = 1.5,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <clipPath id="clip0_1536_28993">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
      <g clipPath="url(#clip0_1536_28993)">
        <path
          d="M3.9375 0.5625H0.5625V3.9375H3.9375V0.5625Z"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.9375 7.3125H0.5625V10.6875H3.9375V7.3125Z"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.9375 14.0625H0.5625V17.4375H3.9375V14.0625Z"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.3125 2.8125H17.4375"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.3125 9.5625H17.4375"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.3125 16.3125H17.4375"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
