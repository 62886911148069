import React, { FC } from 'react';
import styled, { keyframes, CSSProperties } from 'styled-components';
import colors from '@pickles/shared/utils/colors';

export interface ILoaderProps {
  borderWidth?: CSSProperties['borderWidth'];
  borderColor?: CSSProperties['borderColor'];
  indicatorColor?: CSSProperties['borderColor'];
  width?: CSSProperties['width'];
  height?: CSSProperties['height'];
  id?: string;
}

const Loading: FC<ILoaderProps> = (props) => {
  return <LoadingIndicator {...props} />;
};

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const LoadingIndicator = styled.div<ILoaderProps>`
  border: 5px solid ${colors.mischka_024};
  border-top: 5px solid ${colors.san_juan_grey};
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spin} 1s linear infinite;
`;

export const LoadingIndicatorHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;
`;

export default Loading;
