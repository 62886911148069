import colors from '@pickles/shared/utils/colors';
import React, { FC } from 'react';
import { IconInterface } from '.';

export const DocumentsIcon: FC<IconInterface> = ({
  width = 18,
  height = 18,
  strokeColor = colors.blumine_main_blue,
  strokeWidth = 1.5,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.96399 6.02659L6.58336 9.40722C6.21413 9.77645 6.0067 10.2772 6.0067 10.7994C6.0067 11.058 6.05763 11.314 6.15657 11.5528C6.25551 11.7917 6.40054 12.0088 6.58336 12.1916C6.76619 12.3744 6.98323 12.5194 7.2221 12.6184C7.46098 12.7173 7.717 12.7683 7.97555 12.7683C8.49772 12.7683 8.99851 12.5608 9.36774 12.1916L13.942 7.61734C14.3076 7.25169 14.5977 6.8176 14.7956 6.33986C14.9935 5.86211 15.0953 5.35007 15.0953 4.83296C15.0953 4.31586 14.9935 3.80382 14.7956 3.32607C14.5977 2.84833 14.3076 2.41424 13.942 2.04859C13.5763 1.68294 13.1423 1.39289 12.6645 1.195C12.1868 0.997115 11.6747 0.895264 11.1576 0.895264C10.6405 0.895264 10.1285 0.997115 9.65072 1.195C9.17298 1.39289 8.73889 1.68294 8.37324 2.04859L3.40186 7.02109C2.29417 8.12878 1.67187 9.63114 1.67188 11.1977C1.67188 12.7642 2.29417 14.2665 3.40186 15.3742C4.50956 16.4819 6.01191 17.1042 7.57843 17.1042C9.14494 17.1042 10.6473 16.4819 11.755 15.3742L16.3281 10.8"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
