import React, { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';

import { RootState } from '@pickles/shared/redux';
import colors from '@pickles/shared/utils/colors';
import { WEBAPP_AUCTION_ROUTE_PATH } from '@pickles/shared/config/env';

import sizes from '../../../util/sizes';
import useGoBack from '../../../hooks/useGoBack';

import Container from '../Container';
import { ArrorwBackIcon } from '../Icons';
import styled from 'styled-components';

interface BackButtonProps {
  isHiddenText?: boolean;
  text?: string;
  path?: string;
}

export const BackButton: FC<BackButtonProps> = ({ isHiddenText, text = 'Back', path }) => {
  const router = useRouter();
  const goBack = useGoBack();
  const savedPath = useSelector<RootState, string>((state) => state.web.savedPath);

  const { auctionRef } = router.query;
  const isLiveAuctionPage = router.asPath.endsWith('/live');

  const onClick = useCallback(() => {
    const historyLength = window.history.length;

    if (path) {
      return router.push(`${path}`);
    }

    if (isLiveAuctionPage) {
      return router.replace(`/${WEBAPP_AUCTION_ROUTE_PATH}/${auctionRef}`);
    }

    goBack();
  }, [savedPath, goBack]);

  return (
    <BackButtonWrapper alignItems="flex-start" onClick={onClick} id="back-button-wrapper">
      <ArrorwBackIcon width={18} height={22} />
      {!isHiddenText && <div>{text}</div>}
    </BackButtonWrapper>
  );
};

const BackButtonWrapper = styled(Container)`
  font-family: BasierCircle-SemiBold;
  font-size: 16px;
  cursor: pointer;
  color: ${colors.blumine_main_blue};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 16px;
  max-width: ${sizes.XL};
  margin: 0 auto;
  padding-top: 29px;
  padding-bottom: 18px;
  padding-left: 0px;
  padding-right: 0px;

  @media (max-width: ${sizes.XL}) {
    padding-left: 60px;
    padding-right: 60px;
  }

  @media (max-width: ${sizes.M}) {
    padding-top: 0px;
    padding-left: 50px;
    padding-right: 50px;
  }

  @media (max-width: ${sizes.S}) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;
