import colors from '@pickles/shared/utils/colors';
import React, { FC } from 'react';
import { IconInterface } from '.';

export const SoundOnIcon: FC<IconInterface> = ({
  width = 20,
  height = 20,
  strokeColor = colors.san_juan_grey,
  strokeWidth = 2,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4030_42267)">
        <path
          d="M 8.3346 13.3332 H 1.668 V 6.6665 H 8.3346 L 15.0013 1.6665 V 18.3335 Z"
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
