import styled, { css, CSSProperties } from 'styled-components';
import _ from 'lodash';

interface ContainerProps {
  width?: CSSProperties['width'];
  height?: CSSProperties['height'];
  maxWidth?: CSSProperties['maxWidth'];
  minWidth?: CSSProperties['minWidth'];
  maxHeight?: CSSProperties['maxHeight'];
  minHeight?: CSSProperties['minHeight'];
  display?: CSSProperties['display'];
  flex?: CSSProperties['flex'];
  alignItems?: CSSProperties['alignItems'];
  justifyContent?: CSSProperties['justifyContent'];
  flexDirection?: CSSProperties['flexDirection'];

  padding?: CSSProperties['padding'];
  px?: CSSProperties['paddingLeft'] | CSSProperties['paddingRight'];
  py?: CSSProperties['paddingTop'] | CSSProperties['paddingBottom'];
  pb?: CSSProperties['paddingBottom'];
  pt?: CSSProperties['paddingTop'];

  margin?: CSSProperties['margin'];
  mx?: CSSProperties['marginLeft'] | CSSProperties['marginRight'];
  my?: CSSProperties['marginTop'] | CSSProperties['marginBottom'];
  mb?: CSSProperties['marginBottom'];
  mt?: CSSProperties['marginTop'];
}

const defaultDisplay: CSSProperties['display'] = 'flex';
const defaultFlexDirection: CSSProperties['flexDirection'] = 'column';

const transformValueToPx = (value: number | string) => (_.isNumber(value) ? `${value}px` : value);

const Container = styled.div<ContainerProps>`
  display: ${(props) => props.display || defaultDisplay};
  flex-direction: ${(props) => props.flexDirection || defaultFlexDirection};
  width: ${(props) => props.width || '100%'};
  align-items: ${(props) => props.alignItems || 'center'};
  justify-content: ${(props) => props.justifyContent || 'center'};

  ${(props) =>
    props.flex &&
    css`
      flex: ${props.flex};
    `};

  ${(props) =>
    props.padding &&
    css`
      padding: ${transformValueToPx(props.padding)};
    `};

  ${(props) =>
    props.margin &&
    css`
      margin: ${transformValueToPx(props.margin)};
    `};

  ${(props) =>
    props.mb &&
    css`
      margin-bottom: ${transformValueToPx(props.mb)};
    `};

  ${(props) =>
    props.mt &&
    css`
      margin-top: ${transformValueToPx(props.mt)};
    `};

  ${(props) =>
    props.mx &&
    css`
      margin-left: ${transformValueToPx(props.mx)};
      margin-right: ${transformValueToPx(props.mx)};
    `};

  ${(props) =>
    props.my &&
    css`
      margin-top: ${transformValueToPx(props.my)};
      margin-bottom: ${transformValueToPx(props.my)};
    `};

  ${(props) =>
    props.px &&
    css`
      padding-left: ${transformValueToPx(props.px)};
      padding-right: ${transformValueToPx(props.px)};
    `};

  ${(props) =>
    props.py &&
    css`
      padding-top: ${transformValueToPx(props.py)};
      padding-bottom: ${transformValueToPx(props.py)};
    `};

  ${(props) =>
    props.pb &&
    css`
      padding-bottom: ${transformValueToPx(props.pb)};
    `};

  ${(props) =>
    props.pt &&
    css`
      padding-top: ${transformValueToPx(props.pt)};
    `};

  ${(props) =>
    props.height &&
    css`
      height: ${transformValueToPx(props.height)};
    `};

  ${(props) =>
    props.maxWidth &&
    css`
      max-width: ${transformValueToPx(props.maxWidth)};
    `};

  ${(props) =>
    props.minWidth &&
    css`
      min-width: ${transformValueToPx(props.minWidth)};
    `};

  ${(props) =>
    props.maxHeight &&
    css`
      max-height: ${transformValueToPx(props.maxHeight)};
    `};

  ${(props) =>
    props.minHeight &&
    css`
      min-height: ${transformValueToPx(props.minHeight)};
    `};
`;

export default Container;
