import colors from '@pickles/shared/utils/colors';
import React, { FC } from 'react';
import { IconInterface } from '.';

export const PrebidIcon: FC<IconInterface> = ({
  width = 28,
  height = 28,
  fillColor = colors.blumine_main_blue,
  strokeColor = colors.blumine_main_blue,
  strokeWidth = 1.5,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.1"
        d="M0 8C0 3.58172 3.58172 0 8 0H20C24.4183 0 28 3.58172 28 8V20C28 24.4183 24.4183 28 20 28H8C3.58172 28 0 24.4183 0 20V8Z"
        fill={fillColor}
      />
      <path
        d="M17.2851 10.2734L12.7773 14.7812L11 12.9961"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.84375 22.0312H21.2188"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.4062 18.4737H7.65625C7.06975 18.4737 6.59375 18.0936 6.59375 17.6252V7.44232C6.59375 6.97391 7.06975 6.59375 7.65625 6.59375H20.4062C20.9927 6.59375 21.4688 6.97391 21.4688 7.44232V17.6252C21.4688 18.0936 20.9927 18.4737 20.4062 18.4737Z"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
