import colors from '@pickles/shared/utils/colors';
import React, { FC } from 'react';
import { IconInterface } from '.';

export const TimerIcon: FC<IconInterface> = ({
  width = 60,
  height = 60,
  strokeColor = colors.blumine_main_blue,
  strokeWidth = 3,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30 17.5V30L40 40"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.585 2.82489L54.4075 17.3174L40.1775 13.3524"
        stroke={colors.cerulean_blue}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M54.4075 17.3175C51.3095 11.3494 46.1323 6.72328 39.8546 4.31365C33.577 1.90403 26.634 1.87796 20.3385 4.24037C14.0429 6.60278 8.83106 11.1899 5.68834 17.1345C2.54562 23.0792 1.68984 29.9692 3.2828 36.502C4.87576 43.0348 8.80706 48.7576 14.3334 52.5884C19.8597 56.4191 26.5981 58.0923 33.2745 57.2917C39.9509 56.491 46.1025 53.2719 50.5664 48.2431C55.0302 43.2143 57.4969 36.7242 57.5 30"
        stroke={colors.cerulean_blue}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
