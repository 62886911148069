import colors from '@pickles/shared/utils/colors';
import React, { FC } from 'react';
import { IconInterface } from '.';

export const ArrowDownIcon: FC<IconInterface> = ({
  width = 24,
  height = 24,
  strokeColor = colors.san_juan_grey,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M19 9L12 16L5 9" stroke={strokeColor} strokeWidth="2" strokeMiterlimit="10" />
    </svg>
  );
};
